import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
import { Typography, Button, Grid, Box } from "@mui/material"

const Hero = () => {
  const { photo } = useStaticQuery(query)
  return (
    <Box
      component="div"
      sx={{
        px: {
          xs: "2rem",
          sm: "4rem",
        },
        minHeight: "calc(100vh - 5rem)",
        display: "flex",
        alignContent: "center",
      }}
    >
      <Grid container spacing={4} sx={{ alignItems: "center" }}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "3rem",
                  md: "4rem",
                },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Hi, I'm Ha.
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              sx={{ mb: 3, textAlign: { xs: "center", sm: "left" } }}
            >
              Digital Marketer - Web Developer
            </Typography>
            <Link to="/contact">
              <Button variant="outlined">Contact me</Button>
            </Link>
            <SocialLinks />
          </Box>
        </Grid>
        <Grid item xs={0} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
          <GatsbyImage
            image={getImage(photo)}
            className="right-img"
            alt="Ha Truong"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const query = graphql`
  {
    photo: file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default Hero
