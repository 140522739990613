export const jobData = [
  {
    id: "1",
    company: "Expatolife",
    date: "2017 - Now",
    position: "Founder",
    jobDescription: [
      "Develop responsive WordPress sites using modern web technologies.",
      "Research keywords, write, publish, and promote the content.",
      "Maximize site traffic, reaching over 150K views per month",
      "Develop business relationships for affiliate marketing and sponsorships",
    ],
  },
  {
    id: "2",
    company: "Manabie",
    date: "2022",
    position: "Frontend Engineer",
    jobDescription: [
      "Developed and managed web apps using React.",
      "Applied test-driven development with unit and integration tests",
      "Worked closely with designers, project managers, and QA engineers to create application.",
    ],
  },
  {
    id: "3",
    company: "Baolau",
    date: "2018 - 2019",
    position: "Sales and Marketing Executive",
    jobDescription: [
      "Developed marketing strategies to increase traffic and sales.",
      "Managed company's blog & social media channels.",
      "Managed affiliate program and partnership.",
    ],
  },
  {
    id: "4",
    company: "SOUNDBOKS",
    date: "2016 - 2017",
    position: "Customer Success Assistant",
    jobDescription: [
      "Effectively managed customer requests through mail and phone.",
      "Response to clients' complaints, ensuring prompt resolution to avoid escalation.",
      "Proactively identified opportunities to increase client satisfaction.",
      "Endorsed services and products to clients and developed future business.",
    ],
  },
]
