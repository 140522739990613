import React from "react"
import { FaCode, FaSketch, FaLaptop } from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `Build your websites using WordPress, ReactJS, Gatsby, and NodeJs.`,
  },
  {
    id: 2,
    icon: <FaLaptop className="service-icon" />,
    title: "SEO services",
    text: `Deliver SEO audit, tips, and high-quality SEO-optimized content writing service.`,
  },

  {
    id: 3,
    icon: <FaSketch className="service-icon" />,
    title: "Virtual Assistant",
    text: `Help you with every blogging tasks: social media, email marketing, affiliate marketing, updating & managing websites, etc.`,
  },
]

export default services
