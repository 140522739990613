import React from "react"
import { Box, Card, CardContent, Typography } from "@mui/material"

const ItemCard = ({ item }) => {
  const { icon, title, text } = item
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            gap: "1rem",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 1,
            }}
          >
            {icon}
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
            component="h4"
          >
            {title}
          </Typography>
          <Typography align="center" color="textPrimary" variant="body1">
            {text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ItemCard
