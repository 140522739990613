import React from "react"
import { Box, Container, Grid } from "@mui/material"
import services from "../constants/services"
import ItemCard from "./ItemCard"
import Title from "./Title"

const Items = ({ title, styleClass }) => {
  return (
    <Box
      component="div"
      sx={{
        flexGrow: 1,
        py: 6,
      }}
      className={styleClass}
    >
      <Container maxWidth={false}>
        {title && <Title title={title} />}
        <Grid container spacing={3}>
          {services.map(service => {
            return (
              <Grid
                item
                key={service.id}
                md={4}
                sm={6}
                xs={12}
                sx={{ rowGap: 1 }}
              >
                <ItemCard item={service} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default Items
