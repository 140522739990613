import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/SEO"
import Items from "../components/Items"

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: projects },
  } = data
  return (
    <Layout>
      <Seo title="Home" description="This is Home page" />
      <div className="main-page">
        <Hero />
        <Items styleClass="projects" title="services" />
        <Jobs />
        <Projects projects={projects} title="featured projects" showLink />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { feature: { eq: true } }
      }
      sort: { fields: frontmatter___rate }
    ) {
      nodes {
        id
        slug
        frontmatter {
          rate
          title
          desc
          github
          projectSite
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default IndexPage
